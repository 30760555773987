import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "./sectorSlider.scss"
// import required modules
import { Pagination } from "swiper"
import { Navigation } from "swiper"
import Button from "../Button/Button"
import { Link } from "gatsby"
import parse from "html-react-parser"
import Image from "../../helper/Image"
function SectorSlider(props) {
  const sectorSlider = props.sectorSlider
  return (
    <div className="sector-section">
      <div className="container">
        <div className="sector-header">
          <p className="title">{sectorSlider?.title}</p>
          <div className="sub-title-wrapper">
            {sectorSlider?.subContent && (
              <div className="sub-title">{parse(sectorSlider?.subContent)}</div>
            )}
            {props?.ishhidden && <Button
              button={{
                text: "View sectors",
                url: "/sectors",
                type: "primary",
              }}
            />}
          </div>
        </div>
      </div>
      <div className="sector-slider">
        <Swiper
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1368: {
              slidesPerView: 7,
              spaceBetween: 30,
            },
          }}
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          loop={true}
          className="sector-swiper"
          slidesPerView={2}
          spaceBetween={20}
        >
          {sectorSlider?.item.map(slide => {
            return (
              <SwiperSlide key={slide?.title}>
                <div className="slider-item">
                  {slide.link ? (
                    <Link to={slide?.link}>
                      <Image image={slide?.icon} />
                      <h4>{slide?.title}</h4>
                    </Link>
                  ) : (
                    <div>
                      <Image image={slide?.icon} />
                      <h4>{slide?.title}</h4>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="prev"></div>
        <div className="next"></div>
      </div>
    </div>
  )
}

export default SectorSlider
